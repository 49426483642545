var href = window.location.href.split("/")
var baseURL = href[0] + "//"
for (var i = 1; i < href.length; i++) {
    baseURL += href[i]
    if (href[i]) {
        break
    }
}
const language = {
    // 底部tab栏
    main: {
        chinese: "點餐",
        english: "Home"
    },
    coupon: {
        chinese: "優惠券",
        english: "Coupons "
    },
    order: {
        chinese: "訂單記錄",
        english: "My Orders"
    },
    mine: {
        chinese: "我的",
        english: "Account "
    },

    // 首页
    loginAndRegister: {
        chinese: "登入",
        english: "Login"
    },
    login: {
        chinese: "登入",
        english: "Login "
    },
    register: {
        chinese: "註冊",
        english: "Register"
    },
    morning: {
        chinese: "早晨",
        english: "Good morning"
    },
    breakfast: {
        chinese: "朝早食番個靚早餐！",
        english: "Start your day with a great breakfast!"
    },

    lunch: {
        chinese: "午安",
        english: "Good afternoon"
    },
    lunchText: {
        chinese: "落定單食晏就唔駛同人迫~",
        english: "Skip the queue & enjoy your lunch break with KFC App!"
    },

    tea: {
        chinese: "夠鐘鬆一鬆",
        english: "It's tea time!"
    },
    teaText: {
        chinese: "較啱時間嘆個茶餐先！",
        english: " Let's have a break~!"
    },
    dinner: {
        chinese: "今晚想食咩？",
        english: "Craving for KFC tonight?"
    },
    dinnerText: {
        chinese: "落定單返屋企慢慢嘆KFC~",
        english: "Delivery or takeaway? You choose."
    },
    siuYeah: {
        chinese: "晚安",
        english: "Good night"
    },
    waiMaiSuDa: {
        chinese: "外賣速遞",
        english: "Delivery"
    },
    haoWeiSuDa: {
        chinese: "好味速遞",
        english: "Delivery"
    },

    start: {
        chinese: "開始",
        english: "Start"
    },
    WaiMaiZiQu: {
        chinese: "堂食/自取 ",
        english: "Takeaway/Dine-in"
    },
    tangShi: {
        chinese: "堂食 ",
        english: "Dine-in"
    },
    orderProgress: {
        chinese: "訂單配送中",
        english: "Delivery in Progress"
    },
    yuJiSongDa: {
        chinese: "預計送到",
        english: "Expected delivery by",
    },
    change: {
        chinese: "更改",
        english: "Change",
    },
    // 登陆
    zhangHaoLogin: {
        chinese: "登入帳戶",
        english: "Login",
    },
    welcomeKFC: {
        chinese: "尊享KFC 會員優惠",
        english: "Enjoy KFC member privileges",
    },
    phone: {
        chinese: "手機",
        english: "Mobile",
    },
    phoneTab: {
        chinese: "手機",
        english: "Mobile",
    },
    email: {
        chinese: "電郵",
        english: "Email",
    },
    password: {
        chinese: "密碼",
        english: "Password",
    },
    memberMe: {
        chinese: "記住我",
        english: "Remember me",
    },
    forgetPassword: {
        chinese: "忘記密碼?",
        english: "Forgot password?",
    },
    dengRu: {
        chinese: "登入",
        english: "Login",
    },
    zhuCeZhangHu: {
        chinese: "尚未登記成為KFC會員? ",
        english: "Not a member yet?",
    },
    zhuCeZhangHuText: {
        chinese: "立即登記專享各種禮遇及優惠",
        english: "Sign up for exclusive discounts and latest offers!",
    },
    newPas: {
        chinese: "獲取新密碼",
        english: "Get a new password",
    },
    newPasSMS: {
        chinese: "系統會發送新密碼至您的手機",
        english: "New password will be sent to your mobile",
    },
    New_QSRSJH: {
        chinese: "請輸入手機號碼",
        english: "Enter Mobile Numbe"
    },
    confirm: {
        chinese: "確認",
        english: "Confirm",
    },
    nonePhone: {
        chinese: "請輸入有效嘅手機號碼。",
        english: "Invalid mobile. Please try again.",
    },
    noneAddress: {
        chinese: "請輸入有效嘅電郵地址。",
        english: "Invalid email. Please try again. ",
    },
    weiZhuCeKFC: {
        chinese: "咦~呢個手機號碼仲未係KFC會員喎。",
        english: "No record found. Don't have an account? ",
    },
    xiangYouHui: {
        chinese: "立即登記尊享會員優惠",
        english: "Register now for exclusive privileges ",
    },
    mustDendJi: {
        chinese: "即去登記",
        english: "Go to Register ",
    },
    huoQuXinNiMa: {
        chinese: "密碼唔啱噃﹐按「忘記密碼」獲取新密碼啦。",
        english: "Incorrect password. Get a new password via forget password. ",
    },
    newPasAgain: {
        chinese: "新密碼唔啱噃﹐請重新試過。",
        english: "New password does not match. Please try again. ",
    },
    // GPS
    noWIFI: {
        chinese: "搵唔到你而家係邊添​",
        english: "Oops...cannot find your location​ ",
    },
    daKaiWIFI: {
        chinese: "請開啟網絡進行定位服務。",
        english: "Please connect to a network for location services. ",
    },
    kaiFang: {
        chinese: "開啟",
        english: "Connect ",
    },
    kaiFnotLocation: {
        chinese: "搵唔到你而家係邊添​",
        english: "Oops...cannot find your location​ ",
    },
    xuanZe: {
        chinese: "選擇",
        english: "Choose ",
    },
    NA: {
        chinese: "基本會員",
        english: "<member tier>",
    },
    successLocation: {
        chinese: "响呢度：",
        english: "Current: ",
    },
    querenLocation: {
        chinese: "請確認你嘅位置。 ​",
        english: "​​​Please confirm your location.  ",
    },
    // 选择地址
    nowLocation: {
        chinese: "現在位置",
        english: "Current Location",
    },
    chooseAddress: {
        chinese: "選擇我嘅地址",
        english: "Choose My Address ",
    },
    choosere: {
        chinese: "選擇地址",
        english: "Choose Address"
    },
    addNewAddress: {
        chinese: "添加新地址",
        english: "Add New address ",
    },

    xuanZeDiZhi: {
        chinese: "(can take out from map)",
        english: "(can take out from map) ",
    },
    shuRuName: {
        chinese: "輸入大廈名稱或街名搜尋地址",
        english: "Enter Building or Street Name for Nearby Store",
    },
    nearby: {
        chinese: "附近位置",
        english: "Nearby ",
    },
    // store address
    useMyLocation: {
        chinese: "使用現在位置",
        english: "Use my location ",
    },
    searchKeyword: {
        chinese: "請輸入關鍵字搜尋",
        english: "Search by keywords ",
    },
    nearbyShop: {
        chinese: "附近",
        english: "Nearby ",
    },
    recentShop: {
        chinese: "常去",
        english: "Frequently Visited",
    },
    fenDianZiLiao: {
        chinese: "分店資料",
        english: "Store Details",
    },
    pleaseSelect: {
        chinese: "請選擇取餐方式",
        english: "Please select ",
    },
    businessHours: {
        chinese: "營業時間",
        english: "Hours",
    },
    tel: {
        chinese: "電話",
        english: "Telephone",
    },
    address: {
        chinese: "地址",
        english: "Address ",
    },
    startOrder: {
        chinese: "開始點餐",
        english: "Order Now",
    },
    // 6_Store Scenarios
    nearNoShop: {
        chinese: "抱歉，附近暫無服務分店，請更改地址。",
        english: "Sorry, no store found near you. Please change your address. ",
    },
    noneTime: {
        chinese: "抱歉，手機點餐截止本分店營業時間結束前30分鐘。你可以聽日再落單，㗎",
        english: "Sorry, mobile ordering service is only available until 30 mins prior to store closing time. Please come back tomorrow for ordering service.  ",
    },
    selectOtherShop: {
        chinese: "抱歉，本分店現在繁忙中。請選擇附近其他分店。",
        english: "Sorry, the selected store is busy at the moment. Please choose other stores. ",
    },
    gotIt: {
        chinese: "返回",
        english: "Got it ",
    },
    // menu
    pickAtStore: {
        chinese: "落單後憑取餐號碼到店自取",
        english: "Pick up your order with the order number at store. ",
    },
    suDa: {
        chinese: "速遞",
        english: "Delivery ",
    },
    meiWeiSuDa: {
        chinese: "美味速遞",
        english: "Delivery",
    },
    ziQu: {
        chinese: "自取",
        english: "Self-Pick-up ",
    },
    jiShi: {
        chinese: "即時",
        english: "Now",
    },
    yuYue: {
        chinese: "預約",
        english: "Preorder",
    },
    liJiDianCan: {
        chinese: "仲未揀嘢食喎？立即點餐！",
        english: "Hungry? Start order now!",
    },
    yiXuanZe: {
        chinese: "已選擇",
        english: "Selected item(s)",
    },
    xuanZeXiaoShi: {
        chinese: "選擇小食（多項）",
        english: "Select snack (Multiple) ",
    },
    xuanZeYinPin: {
        chinese: "選擇飲品",
        english: "Select drink ",
    },
    addCart: {
        chinese: "加入購物籃",
        english: "Add to Busket ",
    },
    quXiao: {
        chinese: "取消",
        english: "Cancle ",
    },
    selectTime: {
        chinese: "預約",
        english: "Preorder",
    },
    xuanXiang: {
        chinese: "選項",
        english: "Options ",
    },
    xuanHaol: {
        chinese: "揀好啦",
        english: "Confirm ",
    },
    jiXuDianDan: {
        chinese: "繼續落單?",
        english: "Continue to Order? ",
    },
    noTiGong: {
        chinese: "抱歉，你揀既（items）本分店暫時未有供應。",
        english: "Sorry, (items) is/are currently not available at the selected store. ",
    },
    gengGai: {
        chinese: "更改",
        english: "Change ",
    },
    jiXu: {
        chinese: "繼續",
        english: "Continue ",
    },
    nearJin: {
        chinese: "本分店距離你而家位置較遠。",
        english: "The selected store is far away from your current location. ",
    },
    noShowWan: {
        chinese: "抱歉，你揀既（items）暫時售罄。",
        english: "Sorry, (items) is/are currently out of stock. ",
    },
    quXiaoDianDan: {
        chinese: "取消落單?",
        english: "Cancel Order? ",
    },
    startNewOrder: {
        chinese: "重新落單?",
        english: "Start New Order? ",
    },
    cartQingKong: {
        chinese: "你既購物籃會被清空。",
        english: "Your basket will be cleared ",
    },
    fanHui: {
        chinese: "關閉",
        english: "Back ",
    },
    // 注册
    huiYuanDengJi: {
        chinese: "會員登記",
        english: "Sign Up",
    },
    new_tianXieZiLiao: {
        chinese: "填寫資料",
        english: "Fill Info"
    },
    new_wanChengDengJi: {
        chinese: "完成登記",
        english: "Complete"
    },
    new_ZC_shuRuShouJiHao: {
        chinese: "手機號碼",
        english: "Mobile Number"
    },
    new_ZC_shuRuYanZhengMa: {
        chinese: "驗證碼",
        english: "Verification Code"
    },
    new_ZC_huoQuYanZhengMa: {
        chinese: "獲取驗證碼",
        english: "Get Code"
    },
    new_ZC_chongXinFaSong: {
        chinese: "重新發送",
        english: "Resend "
    },
    new_ZC_shuRuDianYou: {
        chinese: "電郵地址",
        english: "Email Address"
    },
    new_ZC_yiSiZhengCe: {
        chinese: "隱私政策",
        english: "Privacy Policy"
    },
    new_ZC_ji: {
        chinese: "及",
        english: "and"
    },
    new_ZC_tiaoKuanXiZe: {
        chinese: "使用條款及細則",
        english: "Terms & Conditions"
    },
    niDeZiLiao: {
        chinese: "你嘅資料",
        english: "Your Profile ",
    },
    sheZhiMiMa: {
        chinese: "設置密碼",
        english: "Password Setup",
    },
    shouJiHaoMa: {
        chinese: "手機號碼",
        english: "Mobile",
    },
    yanZhengMa: {
        chinese: "驗證碼",
        english: "Verification ",
    },
    chondXinFaSong: {
        chinese: "重新發送",
        english: "Re-send ",
    },
    jieShouKFC: {
        chinese: "電郵",
        english: "Email",
    },
    jieShouKFCText: {
        chinese: "（接收KFC最新著數優惠）",
        english: "(Be the first to receive KFC offers) ",
    },
    noJieShouKFC: {
        chinese: "我願意接收有關KFC的最新優惠及其他資訊",
        english: "I would like to receive updates and promotional offers from KFC",
    },
    zhengCeXieXi: {
        chinese: "我已閱讀並同意相關的",
        english: "I have read and agree to the ",
    },
    xiaYiBu: {
        chinese: "下一步",
        english: "Proceed",
    },
    youXiaoShouJiHao: {
        chinese: "請輸入有效既手機號碼。",
        english: "Invalid mobile. Please try again. ",
    },
    youXiaoDiZhi: {
        chinese: "請輸入有效既電郵地址。",
        english: "Invalid email. Please try again. ",
    },
    yiJinfKFC: {
        chinese: "你已經係KFC會員啦喎。",
        english: "You're a KFC member already. ",
    },
    miMa: {
        chinese: "密碼",
        english: "Password",
    },
    new_ZC_queRenMiMa: {
        chinese: "確認密碼",
        english: "Confirm Password"
    },
    new_ZCzaiCiShuRuMiMa: {
        chinese: "再次輸入密碼",
        english: "Re-enter Password"
    },
    miMaGuiZe: {
        chinese: "6-20位字母、數字或符號",
        english: "6-20 characters of alphabets, numbers or symbols",
    },
    wanCheng: {
        chinese: "確認",
        english: "Confirm ",
    },
    welcomeKFCHuiYuan: {
        chinese: "歡迎你成為KFC會員",
        english: "Welcome to be a KFC member ",
    },
    kaiShiDianCan: {
        chinese: "開始點餐",
        english: "Start Order ",
    },
    queRenDingdan: {
        chinese: "已確認",
        english: "Confirmed",
    },
    gengGaiDiZhi: {
        chinese: "更改地圵",
        english: "Change ",
    },
    sonDaShiJian: {
        chinese: "送遞時間",
        english: "Delivery time ",
    },
    canJu: {
        chinese: "餐具",
        english: "Cutlery ",
    },
    huanBaoCanJu: {
        chinese: "(支持環保走餐具)",
        english: "(Go Green, no utensils from us) ",
    },
    zhouQuanSongDa: {
        chinese: "無接觸送餐服務",
        english: "Contact-free Delivery",
    },
    zhiDIngDiDian: {
        chinese: "大堂/指定地點等",
        english: "Lobby / Special Location",
    },
    jianYiRenShi: {
        chinese: "請問外送地址有沒有正接受「家居檢疫人士」?",
        english: "Is anyone confined to home quarantine at the delivery address? ",
    },
    jianYiAlert: {
        chinese: "只限網上付款。為減少病毒傳播風險，外送速遞員會加強保護裝備並送餐至門口，然後致電通知顧客自行提取。",
        english: "Accepts online payment only. The order will be left by your door and a call will be made to you for pickup in order to reduce the risk of infection.",
    },
    dingDanXiangQing: {
        chinese: "訂單詳情",
        english: "Order Details ",
    },
    more: {
        chinese: "更多",
        english: "More ",
    },
    collapse: {
        chinese: "收起",
        english: "Collapse  ",
    },
    jioaDaiFei: {
        chinese: "膠袋徵費(如適用)",
        english: "Plastic bag levy (if any)  ",
    },
    peiSongFei: {
        chinese: "運費",
        english: "Delivery fee ",
    },
    xiaoJiJinE: {
        chinese: "合計",
        english: "Subtotal ",
    },
    hungryMore: {
        chinese: "想再嘆啲？",
        english: "Add-ons",
    },
    useCoupon: {
        chinese: "優惠券",
        english: "Coupons",
    },
    used: {
        chinese: "你已選用 XXXX ",
        english: "You've used XXXX ",
    },
    youHuiMa: {
        chinese: "請輸入優惠碼",
        english: "Enter promo code ",
    },
    apply: {
        chinese: "使用",
        english: "Apply ",
    },
    Payment: {
        chinese: "付款方式",
        english: "Payment Methods",
    },
    huiYuanJiFen: {
        chinese: "會員積分",
        english: "Membership Points",
    },
    wu: {
        chinese: "無",
        english: "No",
    },
    age18: {
        chinese: "我已年滿18歲",
        english: "I'm aged 18 or above",
    },
    agreeXieXi: {
        chinese: "我已閱讀並同意相關的<a href='https://myapp.kfc.com.hk/c/privacy_tc.html' style='color:#218bce'>私隱政策</a>及<a href='" + baseURL + "/#/agreement' style='color:#218bce'>使用條款及細則</a>",
        english: "I have read and agree to the <a href='https://myapp.kfc.com.hk/c/privacy_en.html' style='color:#218bce'>Privacy Policy</a> and <a href='" + baseURL + "/#/agreement' style='color:#218bce'>Terms Conditions</a>.",
    },

    jieZhang: {
        chinese: "*實際價錢以結帳時為準。",
        english: "*The order price is subject to final payment",
    },
    checkout: {
        chinese: "去付款",
        english: "Checkout",
    },
    selectCoupon: {
        chinese: "選擇優惠券",
        english: "Select Coupon",
    },
    woDeDiZhi: {
        chinese: "我的地址",
        english: "My Addresses",
    },
    new_WD_guanLiDiZhi: {
        chinese: "管理地址",
        english: "Manage Addresses"
    },
    tianJiaXinDiZhi: {
        chinese: "添加新地址",
        english: "Add New Address",
    },
    xiangXiDiZhi: {
        chinese: "詳細地址",
        english: "Full Address",
    },
    zuoShuo: {
        chinese: "座",
        english: "Block",
    },
    cengShu: {
        chinese: "樓層",
        english: "Floor",
    },
    danWei: {
        chinese: "單位",
        english: "Unit",
    },
    lable: {
        chinese: "標籤",
        english: "Lable",
    },
    note: {
        chinese: "備註",
        english: "Note",
    },
    xiaoYu26: {
        chinese: "不超過26個字",
        english: "No more than 26 words",
    },
    lianXiRen: {
        chinese: "聯絡人",
        english: "Name",
    },
    name: {
        chinese: "姓名",
        english: "Name",
    },
    yuSheDiZhi: {
        chinese: "預設地址",
        english: "Default address",
    },
    save: {
        chinese: "保存",
        english: "Save",
    },
    saved: {
        chinese: "已保存",
        english: "Saved",
    },
    fuKuanFanShi: {
        chinese: "選擇付款方式",
        english: "Payment Method",
    },
    xinYongKa: {
        chinese: "VISA/MasterCard 信用卡",
        english: "VISA/MasterCard ",
    },
    xinYongKa2: {
        chinese: "恆生信用卡Cash Dollar",
        english: "Hang Seng Cash Dollars",
    },
    baDaTong: {
        chinese: "八達通",
        english: "Octopus",
    },
    Alipay: {
        chinese: "支付寶(內地)",
        english: "Alipay",
    },
    cash: {
        chinese: "現金",
        english: "Cash",
    },
    addCard: {
        chinese: "添加信用卡",
        english: "Add Card",
    },
    myCard: {
        chinese: "管理付款方式",
        english: "Payment Methods",
    },
    fanHui2: {
        chinese: "返回",
        english: "Back",
    },
    shuRuKaHao: {
        chinese: "輸入信用卡號碼",
        english: "Enter Credit Card Number",
    },
    saveCard: {
        chinese: "保存信用卡資料",
        english: "Save credit card",
    },
    tianXieDiZhi: {
        chinese: "公司或大廈名稱、街名(如適用)",
        english: "Enter a building name or street name (if any)",
    },
    gender: {
        chinese: "稱謂",
        english: "Title",
    },
    new_GRZL_xingBie: {
        chinese: "性別",
        english: "Gender"
    },
    male: {
        chinese: "先生",
        english: "Male",
    },
    female: {
        chinese: "女士",
        english: "Female",
    },
    baoCunDiZhi: {
        chinese: "保存地址",
        english: "Save",
    },
    shouDaoDingDan: {
        chinese: "多謝！已收到你的訂單",
        english: "Thank you for your order!",
    },
    dingDanZhuangTai: {
        chinese: "我們會盡快為你送上美食！你可於我的訂單查看訂單狀態。",
        english: 'We will be preparing your meal soon, please check the status at "My Orders" page.',
    },
    songHuiYuan: {
        chinese: "咪走住，登記埋做KFC會員：",
        english: "Don't miss out KFC member privileges. Register now!",
    },
    songYiJian: {
        chinese: "免費送你迎新獎賞「經典葡撻」一件",
        english: "Get a Free Egg Tart",
    },
    baoCunFangBian: {
        chinese: "儲低你嘅地址、過去訂單等，下次落單更快更方便",
        english: "Speed up ordering with your saved address and order history",
    },
    duXiangYouHui: {
        chinese: "獨享會員著數優惠",
        english: "Enjoy member-exclusive deals",
    },
    xinPinQingBao: {
        chinese: "緊貼KFC新品情報",
        english: "Be the first to receive KFC's latest news",
    },
    jiQuDengJi: {
        chinese: "即去登記",
        english: "Go to Register",
    },
    quCanHaoMa: {
        chinese: "取餐號碼",
        english: "Order Number",
    },
    hanYouJiuJing: {
        chinese: "由於訂單含有醺醉的酒類，收件人必須年滿18歲。 如送遞人員在交收過程中對收件人的年齡有任何疑問，送遞人員將檢查收件人的身份證證明文件，如收件人未滿18歲或拒絕出示其身份證明文件，送遞人員將不會把訂單食物傳遞給收件人。 謹此聲明本人已年滿18歲。",
        english: "While this order contains alcoholic beverage, recipient must be above 18 years. Proof of age will be required if our staff have any enquiries on the customer’s age. If the recipient confirms that he is under 18 years old or refuses to present his identification document, the order will not be delivered. I declare that I am over 18 years old.",
    },
    tuiJainJiaPei: {
        chinese: "推薦加配",
        english: "Hot recommendation ",
    },
    shiYongTiaoKuan: {
        chinese: "使用條款",
        english: "Terms of Use",
    },
    accept: {
        chinese: "同意",
        english: "Accept",
    },
    woDeZhangHu: {
        chinese: "設定及帳戶",
        english: "My Account & Settings",
    },
    huiYuanMa: {
        chinese: "會員碼",
        english: "My ID",
    },
    jiFenDaoQi: {
        chinese: "積分到期日",
        english: "Points Expiry Date",
    },
    jiFen: {
        chinese: "積分",
        english: "Points",
    },
    woDeDingDan: {
        chinese: "查看訂單",
        english: "Order History",
    },
    new_WD_huiYuanMa: {
        chinese: "會員碼",
        english: "Membership ID"
    },
    new_WD_fenDianWeiZhi: {
        chinese: "分店位置",
        english: "Store Locations"
    },
    woDeJiFen: {
        chinese: "我嘅積分",
        english: "My Points",
    },
    New_WD_jiFenDuiHan: {
        chinese: "積分及兌換獎賞",
        english: "Points & Redemptions"
    },
    new_WD_geRenZiLiao: {
        chinese: "個人資料",
        english: "Profile"
    },
    woDeZiLiao: {
        chinese: "我嘅資料",
        english: "Profile",
    },
    tiaoKuanXieyi: {
        chinese: "條款及細則",
        english: "Terms & Conditions",
    },
    myParment: {
        chinese: "管理付款方式",
        english: "Manage Payment Methods",
    },
    yuYan: {
        chinese: "語言",
        english: "Language",
    },
    diQu: {
        chinese: "地區",
        english: "Region",
    },
    HongKong: {
        chinese: "香港",
        english: "HK",
    },
    macau: {
        chinese: "澳門",
        english: "MO",
    },
    huiYuanZhangHu: {
        chinese: "訪客",
        english: "Guest",
    },
    dengRu1: {
        chinese: "登入",
        english: "Login",
    },
    lianLuoWoMen: {
        chinese: "聯絡我們",
        english: "Contact us",
    },
    username: {
        chinese: "用戶名",
        english: "Username",
    },
    birthday: {
        chinese: "生日",
        english: "Birthday",
    },
    gengGaiMiMa: {
        chinese: "更改密碼",
        english: "Change Password",
    },
    logout: {
        chinese: "登出",
        english: "Log out",
    },
    queDingDengChu: {
        chinese: "確定登出?",
        english: "Log Out?",
    },
    xianYouMima: {
        chinese: "現有密碼",
        english: "Current Password",
    },
    xinMiMa: {
        chinese: "新密碼",
        english: "New Password",
    },
    xianYouMimaZhengQue: {
        chinese: "現有密碼唔正確",
        english: "Current password is incorrect",
    },
    gengGaiChengGong: {
        chinese: "密碼已更改",
        english: "Password has been changed",
    },
    shuruYonghuMing: {
        chinese: "請輸入用戶名",
        english: "Please enter an username",
    },
    jiFenDaoQiRi: {
        chinese: "積分到期日",
        english: "Expiry Date",
    },
    yiHuoDe: {
        chinese: "已獲得",
        english: "Earned",
    },
    yiShiYong: {
        chinese: "已使用",
        english: "Redeemed",
    },
    yiWanChengDingDan: {
        chinese: "已完成訂單",
        english: "Completed Order",
    },
    jiFenDuiHan: {
        chinese: "積分及兌換獎賞",
        english: "Points & Redemptions",
    },
    yiChu: {
        chinese: "移除",
        english: "Remove",
    },
    notSave: {
        chinese: "無保存地址",
        english: "No saved address",
    },
    bianJiDiZhi: {
        chinese: "編輯地址",
        english: "Edit Address",
    },
    notSavedCard: {
        chinese: "無保存任何信用卡",
        english: "No saved credit card",
    },
    baoCun: {
        chinese: "保存",
        english: "add",
    },
    chengGongTianJia: {
        chinese: "成功添加",
        english: "Added",
    },
    quanBu: {
        chinese: "全部XX張",
        english: "All",
    },
    Breakfast: {
        chinese: "早餐",
        english: "Breakfast",
    },
    xiaWuCha: {
        chinese: "下午茶",
        english: "Tea",
    },
    buKeYong: {
        chinese: "不適用",
        english: "Not Applicable",
    },
    liJiShiYong: {
        chinese: "立即使用",
        english: "Apply",
    },
    shiYongShuoMing: {
        chinese: "使用説明",
        english: "How to Use",
    },
    daiMaQuan: {
        chinese: "QR code 設有時效，請勿截圖使用。",
        english: "QR code expires after a designated time. Please do not take a screenshot of the QR code.",
    },
    duiHanShuoMing: {
        chinese: "兌換說明",
        english: "How to Redeem",
    },
    shouDaol: {
        chinese: "已確認",
        english: "Confirmed",
    },
    zhunBeiZhong: {
        chinese: "準備中",
        english: "Preparing",
    },
    nvLiZhiZuo: {
        chinese: "努力製作中，請等等",
        english: "We're preparing your order",
    },
    zhunBeiFinsih: {
        chinese: "拎得啦",
        english: "Ready for Pickup",
    },
    yiWanCheng: {
        chinese: "已完成",
        english: "Completed",
    },
    songDaZhong: {
        chinese: "送餐中",
        english: "Delivering",
    },
    quCanPlace: {
        chinese: "拎住呢個號碼去櫃台取餐就得啦",
        english: "Pick your order at the counter with order number. Enjoy!",
    },
    zongJiJinE: {
        chinese: "合計",
        english: "Subtotal",
    },
    dingDanZiLiao: {
        chinese: "訂單資料",
        english: "Order Details",
    },
    fenDian: {
        chinese: "分店",
        english: "Store",
    },
    yongCanFangShi: {
        chinese: "用餐方式(自取/堂食)",
        english: "Dining Option(Takeaway /Dine-in)",
    },
    xiaDanShiJian: {
        chinese: "落單時間",
        english: "Order Time",
    },
    dingDanHaoMa: {
        chinese: "訂單號碼",
        english: "Order Number",
    },
    xuYao: {
        chinese: "需要/唔需要",
        english: "Yes / No",
    },
    dingDanQuXiao: {
        chinese: "訂單已取消",
        english: "Cancelled Order",
    },
    yiQuXiao: {
        chinese: "已取消",
        english: "Cancelled",
    },
    zaiCiXiaDan: {
        chinese: "再次落單",
        english: "Reorder",
    },
    quanCanHaoMa: {
        chinese: "憑取餐號碼XXX到櫃台取餐",
        english: "Pick your order at the counter with order number XXX. ",
    },
    zuiJinWu: {
        chinese: "你最近無訂單",
        english: "You don't have any orders recently",
    },
    startOrder1: {
        chinese: "即去嗌嘢食",
        english: "Start Order",
    },
    enterPhone: {
        chinese: '請輸入手機號碼',
        english: 'Please enter a valid mobile number'
    },
    fillAddress: {
        chinese: '填寫地址',
        english: 'Address'
    },
    // 我的 後加
    new_WD_songShangYouHui: {
        chinese: "爲你送上尊享優惠",
        english: "Enable notifications to receive the latest offers "
    },
    new_WD_qiYongTuiSOng: {
        chinese: "啓用推送通知，掌握最新資訊",
        english: ""
    },
    new_WD_liJiKaiFang: {
        chinese: "立即開啟 >",
        english: "Enable Now >"
    },
    // 個人信息
    new_GRZL_shanChuZhangHu: {
        chinese: "刪除KFC帳戶",
        english: "Delete KFC Account"
    },
    new_GRZL_dengChu: {
        chinese: "登出",
        english: "Log Out"
    },
    // 訂單列表
    new_DDLB_woDeDingDan: {
        chinese: "我的訂單",
        english: "My Orders"
    },
    // 我的地址
    new_WDDZ_gengGai: {
        chinese: "更改",
        english: "Edit"
    },
    new_WDDZ_wanCheng: {
        chinese: "完成",
        english: "Save"
    },
    // 添加地址
    new_XDZ_beiZhu: {
        chinese: "備注",
        english: "Remarks"
    },
    new_XDZ_moRenDiZhi: {
        chinese: "儲存爲預設",
        english: "Set as Default "
    },
    // 管理付款方式
    new_FKFS_guanLi: {
        chinese: "選擇預設付款方式",
        english: "Select as Default"
    },
    new_FKFS_gengGai: {
        chinese: "更改",
        english: "Edit"
    },
    new_FKFS_wanCheng: {
        chinese: "完成",
        english: "Save"
    },
    // 会员码
    new_HYM_huiYuanMa: {
        chinese: "會員碼",
        english: "Membership ID"
    },
    new_HYM_chuShi: {
        chinese: "於結賬時掃描二維碼",
        english: "Scan QR code upon checkout "
    },
    new_HYM_huiyuanHaoMa: {
        chinese: "會員號碼:",
        english: "Membership No.:"
    },
    new_HYM_jiFenDuiHuan: {
        chinese: "兌換禮品",
        english: "Points & Redemptions"
    },
    // 首页
    new_SY_liJiYueYe: {
        chinese: "立即 / 預約下單",
        english: "Order Now / Preorder"
    },
    new_SY_xuanZeDiZhi: {
        chinese: "先輸入地址，然後揀選取餐方式",
        english: "Enter preferred location, then select dining method"
    },
    new_SY_haoWeiSuDi: {
        chinese: "好味速遞",
        english: "Online Ordering"
    },
    new_SY_tangShiZiQu: {
        chinese: "餐廳點餐",
        english: "Restaurant Ordering"
    },
    new_SY_xianZaiWeiZhi: {
        chinese: "用餐位置:",
        english: "Location:"
    },
    new_SY_gengGai: {
        chinese: "更改",
        english: "Edit"
    },
    // 门店列表
    new_XZCT_xuanZe: {
        chinese: "揀選",
        english: "Select"
    },
    new_XZCT_fenDianWeiZhi: {
        chinese: "分店位置",
        english: "Store Location"
    },
    new_XZCT_xuanZeDiZhi: {
        chinese: "揀選地址",
        english: "Select Delivery Address"
    },
    new_XZCT_woDe: {
        chinese: "我的",
        english: "My Address"
    },
    // 购物车
    new_GWC_qingKong: {
        chinese: "清空購物車",
        english: "Clear Cart"
    },
    // 餐单页
    new_CD_ziQi: {
        chinese: "自取",
        english: "Self-pickup "
    },
    new_CD_dianCan: {
        chinese: "點餐",
        english: "Order"
    },
    // 确认订单
    new_QRDD_queRenDingDan: {
        chinese: "確認訂單",
        english: "Order Confirmation"
    },
    new_QRDD_beiZhu: {
        chinese: "備注",
        english: "Remarks"
    },
    new_QRDD_heJi: {
        chinese: "合計",
        english: "Total"
    },
    new_QRDD_peiSongFei: {
        chinese: "運費",
        english: "Delivery Fee"
    },
    new_QRDD_dengRuKFC: {
        chinese: "已有KFC ID ？立即登入你的KFC帳戶賺取積分 ！",
        english: 'Have a KFC Membership ID? Link your KFC Account now to earn Points!'
    },
    new_QRDD_liJiDengRu: {
        chinese: "立即登入",
        english: "Login Now"
    },
    // 订单详情
    new_DDXQ_fanHui: {
        chinese: "確認",
        english: "Confirm"
    },
    new_DDXQ_songDaShiJian: {
        chinese: "送遞時間",
        english: "Delivery Date & Time"
    },
    new_DDXQ_songCanDiZhi: {
        chinese: "送餐地址",
        english: "Delivery Address"
    },
    new_DDXQ_zffs: {
        chinese: "付款方式",
        english: "Payment Method"
    },
    // 积分兑换
    new_JFDH_jiFenDaoQi: {
        chinese: "積分到期日:",
        english: "Expiry Date:"
    },
    new_JFDH_duiHuan: {
        chinese: "兌換​",
        english: "Redeem"
    },
    login_shouJi: {
        chinese: "手機號碼",
        english: "Mobile Number"
    },
    ZC_msgOne: {
        chinese: "手機號碼已被登記,",
        english: "This mobile number is already"
    },
    ZC_msgTwo: {
        chinese: "請按確認返回登入帳戶頁面",
        english: "registered. Press Confirm to login"
    },
    GR_pas: {
        chinese: "更改密碼",
        english: "Password"
    },
    EDIT_error: {
        chinese: "密碼格式錯誤，重新輸入",
        english: "Password format is invalid, please try again"
    },
    add_new_card: {
        chinese: "添加信用卡",
        english: "Add New Card"
    },
    XYK_del_one: {
        chinese: "無法添加信用卡，",
        english: "Cannot add new card, "
    },
    XYK_del_Two: {
        chinese: "請先移除一張已儲存信用卡",
        english: "please remove one saved credit card first"
    },
    DZ_del: {
        chinese: "確定刪除此地址嗎？​",
        english: "Delete This Address?​"
    },
    DZ_del_cancel: {
        chinese: "取消",
        english: "Cancel​"
    },
    DZ_del_del: {
        chinese: "刪除",
        english: "Delete​"
    },
    DZ_26: {
        chinese: "不超过26个字",
        english: "No more than 26 words"
    },
    DZ_name: {
        chinese: "姓名",
        english: "Name"
    },
    DZ_DianYou: {
        chinese: "電郵地址",
        english: "Email Address"
    },
    YHXQ_alert_one: {
        chinese: "確定使用​",
        english: "Redeem with"
    },
    YHXQ_alert_two: {
        chinese: "兌換嗎？​",
        english: "?"
    },
    YHXQ_alert_three: {
        chinese: "您目前還有",
        english: "You have"
    },
    YHXQ_alert_four: {
        chinese: "積分",
        english: "Points"
    },
    YHXQ_point_none: {
        chinese: "當前積分不足兌換此優惠",
        english: "Insufficient points to redeem this offer"
    },
    DC_time_err_one: {
        chinese: "餐廳點餐只有適用於",
        english: "Restaurant Ordering is"
    },
    DC_time_err_JS: {
        chinese: "即時點餐只有適用於",
        english: "Online Ordering is"
    },
    DC_time_err_morning: {
        chinese: "早上",
        english: "only available from"
    },
    DC_time_err_dinner: {
        chinese: "至晩上",
        english: "to"
    },
    finishBtn: {
        chinese: "確認",
        english: "Confirm"
    },
    YJ_time_err: {
        chinese: "暫時未能預計送達時間",
        english: "Delivery Time Unavailable"
    },
    YF: {
        chinese: "另需加運費 ",
        english: " delivery fee will be applied"
    },
    YF_none: {
        chinese: "已享免運費",
        english: "Free delivery"
    },
    GWC_del: {
        chinese: "已從購物車中移除",
        english: " has been removed from cart"
    },
    GWC_remove: {
        chinese: "所選餐品已從購物車移除",
        english: "Selected items have been removed from cart"
    },
    NUM_top: {
        chinese: "數量已達上限",
        english: "Limit Exceeded"
    },
    cart_none: {
        chinese: "無法加入購物車，尚有未選餐品",
        english: "Unable to add to cart with "
    },
    cart_none_B: {
        chinese: "",
        english: "unselected item(s)"
    },
    NO_vip: {
        chinese: "地址",
        english: "Address"
    },
    QRDD_hm: {
        chinese: "手機號碼",
        english: "Mobile Number"
    },
    QRDD_dy: {
        chinese: "電郵",
        english: "Email Address"
    },
    QRDD_dc: {
        chinese: "餐廳點餐",
        english: "Restaurant Ordering"
    },
    DDXQ_qucan: {
        chinese: "取餐時間",
        english: "Pickup Time"
    },
    FWRX_num: {
        chinese: "客戶服務熱線 :",
        english: "Customer Service Hotline :"
    },
    QC_mendian: {
        chinese: "取餐門店",
        english: "Pickup Store"
    },
    A: {
        chinese: "返回",
        english: "Confirm"
    },
    EP: {
        chinese: "確認",
        english: "Confirm"
    },
    winkA: {
        chinese: "訂單包含酒精飲料",
        english: "Order Contains Alcoholic Beverage(s)"
    },
    quCanHaoMaT: {
        chinese: "取餐號",
        english: "Pickup Code",
    },
    JJJY: {
        chinese: "有家居檢疫人士",
        english: "Is there any Home Confinee at the delivery address?",

    },
    WJCFW: {
        chinese: "無接觸送餐服務",
        english: "Contact-free Delivery?",
    },
    NO: {
        chinese: "否",
        english: "No",
    },
    YES: {
        chinese: "是",
        english: "Yes",
    },
    new_TS_ziQi: {
        chinese: "外賣",
        english: "Takeaway",
    },
    HQXXSB: {
        chinese: "網絡異常，請重試",
        english: "Connection error has occurred, please retry",
    },
    BZPSFWN: {
        chinese: "所選地址不在服務範圍內",
        english: "Provided address is out of service area",
    },
    SYMK: {
        chinese: "優惠使用貼士：訂單滿最低消費金額即可使用優惠",
        english: "Coupon Tips:To enjoy the offer, please meet the minimum spend requirement",
    },
    CDDLXBKY: {
        chinese: "優惠不適用於此取餐方式",
        english: "This promotion is invalid for the selected dining method",
    },
    YHQYGQ: {
        chinese: "優惠已完結",
        english: "This promotion has ended",
    },
    BZSYSJFW: {
        chinese: "優惠須於指定時段內使用",
        english: "This promotion is valid for specific time",
    },
    TDCP: {
        chinese: "優惠使用貼士:加入指定餐品/套餐到購物車即可使用優惠",
        english: "Coupon Tips:To enjoy the offer, please add the required item/combo(s) to the cart",
    },
    BZCD: {
        chinese: "不適用於此餐廳或已售罄",
        english: "Promotion item is unavailable at this restaurant or is sold out",
    },
    HQPSFYHSB: {
        chinese: "網絡異常，請重試",
        english: "Connection error has occurred, please retry",
    },
    QBDYUU: {
        chinese: "請綁定你的yuu ID",
        english: "Please link your yuu ID",
    },
    BFJKSB: {
        chinese: "因網絡異常無法完成你的購買，請重試",
        english: "Unable to complete your purchase due to connection error, please retry",
    },
    QTYXY: {
        chinese: "請剔選表示同意接受用戶協議",
        english: "Please click to accept user agreement",
    },
    QXZDZ: {
        chinese: "請選擇地址",
        english: "Please provide address",
    },
    QTXDY: {
        chinese: "請填寫電郵",
        english: "Please provide email",
    },
    QTXLXR: {
        chinese: "請填寫聯絡人",
        english: "Please provide contact name",
    },
    SJHCW: {
        chinese: "手機號碼格式錯誤",
        english: "Invalid phone number",
    },
    QTXXXDZ: {
        chinese: "請填寫詳細地址",
        english: "Please provide address",
    },
    QXZZFFS: {
        chinese: "請選擇付款方式",
        english: "Please select payment method",
    },
    QSRSJHM: {
        chinese: "請輸入手機號碼",
        english: "Please provide mobile number",
    },
    CPBZPSSJ: {
        chinese: "部分餐品未能於指定時段內供應，請更新購物車",
        english: "Item(s) unavailable at the selected delivery/pick up time, please update cart",
    },
    CPYCS: {
        chinese: "部分餐品未能於時段供應，請更新購物車",
        english: "Item(s) unavailable at the selected delivery/pick up time, please update cart",
    },
    QXZSPGG: {
        chinese: "無法加入購物車，尚有未選餐品",
        english: "Unable to add to cart with unselected item(s)",
    },
    CCXYK: {
        chinese: "儲存信用卡",
        english: "Save This Credit Card",
    },
    LCSRMMBYZ: {
        chinese: "密碼格式錯誤，重新輸入",
        english: "Password format is invalid, please try again",
    },
    WFSYYHQ: {
        chinese: "無法使用優惠碼",
        english: " Promo code cannot be applied",
    },
    DQMDBZYYSJN: {
        chinese: "所選門店暫時未能提供點餐服務 ",
        english: "Selected restaurant is currently unavailable",
    },
    QXZQTMD: {
        chinese: "請親臨購買，或選擇其他分店 ",
        english: "please order at the counter or select another location",
    },
    SXDZBZFWFWN: {
        chinese: "所選地址不在服務範圍內 ",
        english: "Provided address is out of service area",
    },
    JRGWC: {
        chinese: "加入購物車",
        english: "Add to Cart",
    },
    GXGWUC: {
        chinese: "更新購物車",
        english: "Update Cart",
    },
    download: {
        chinese: "下載 KFC 應用程式獲取獨家優惠券",
        english: "EXCLUSIVE OFFERS ON KFC APP!",
    },
    btnText: {
        chinese: "下載/打開",
        english: "OPEN",
    },
    MDFMWM: {
        chinese: "由於疫情持續嚴峻，部分區域將調整營業時間或暫停送貨服務，敬請體諒，一同攜手抗疫！",
        english: "Some areas may adjust business hours or suspend delivery services temporarily amid the surging COVID-19 situation. Thank you for your understanding, and let's fight the pandemic together!",
    },
    MDFMALERTTITLE: {
        chinese: "最新外送安排：",
        english: "Latest Delivery Notice: "
    },
    MDFMTS: {
        chinese: "由於疫情持續嚴峻，所選門店目前暫時未能提供點餐服務。敬請體諒，一同攜手抗疫！",
        english: "Selected restaurant is temporarily unavailable amid the surging COVID-19 situation. Thank you for your understanding,and let's fight the pandemic together!",
    },
    finishIsBusyBtn: {
        chinese: "返回",
        english: "Confirm"
    },
    QSJG: {
        chinese: "餐品起送價格為 ",
        english: "The store does not support self-booking"
    },
    GWCWKYHCW: {
        chinese: "優惠使用貼士：惠顧任何餐品/套餐即可使用優惠",
        english: "The price of sending "
    },
    BZCZQYY: {
        chinese: "門店不支持自取預約",
        english: "The store does not support self-booking"
    },
    BZCWMYY: {
        chinese: "門店不支持外賣速遞預約",
        english: "The store does not support Delivery"
    },
    WDSYMK: {
        chinese: "優惠使用貼士：",
        english: "Offer Tips: "
    },
    WDSYMKSUB: {
        chinese: "✻現在前往付款將從購物車中移除優惠券",
        english: "To enjoy the offer, please meet the<br/>minimum spend requirement"
    },
    WDSYMKSUBJE: {
        chinese: "訂單滿最低消費金額即可使用優惠",
        english: "✻Coupon will be removed from the cart<br/>if proceed to checkout now"
    },
    WDSYMKSUBCP: {
        chinese: "加入指定餐品/套餐到購物車即可使用優惠",
        english: "To enjoy the offer, please add the<br/>required item/combo(s) to the cart"
    },
    JXXG: {
        chinese: "繼續選購",
        english: "Browse Menu"
    },
    QWFK: {
        chinese: "前往付款",
        english: "Checkout"
    },
    BZCDHYSQ: {
        chinese: "抱歉！贈送或優惠餐品/套餐不適用於此餐廳或已售罄",
        english: "Sorry! The freebie or promotional item/<br/>combo(s)  is unavailable<br/>at this restaurant or is sold out"
    },
    BZCDHYSQSUB: {
        chinese: "✻您可繼續選購/選用其他著數優惠券",
        english: "✻You may browse the menu/<br/>use other coupons"
    },
    BZDQSJDSUB: {
        chinese: "優惠須於指定時段內使用",
        english: "The offer is valid for a specific time"
    },
    BZDQSJDSUBF: {
        chinese: "✻您可更改時間/選用其他著數優惠券。<br/>現在前往付款將從購物車中移除優惠券",
        english: "✻You may change the time/use other<br/>coupons. Coupon will be removed from<br/>the cart if proceed to checkout now"
    },
    MZDDZKSYYZQSUB: {
        chinese: "每張訂單只可使用一張優惠券",
        english: "Only one coupon can be<br/>used per transaction"
    },
    MZDDZKSYYZQSUBE: {
        chinese: "✻如欲使用其他優惠，請先從購物車中<br/>移除原有的優惠券",
        english: "✻Please remove the existing coupon<br/>from the cart if you wish to<br/>use other offers"
    },
    YCYHQ: {
        chinese: "是否移除優惠券？",
        english: "Remove Coupon?"
    },
    YCYHQSUB: {
        chinese: "✻折扣將從訂單中移除",
        english: "✻Discount will be removed<br/>from the order"
    },
    YCYHQCPSUB: {
        chinese: "✻贈送或優惠餐品/套餐將從購物車中移除",
        english: "✻The freebie or promotional item/<br/>combo(s) will be removed from the order"

    },
    QRDDMK: {
        chinese: "✻您可繼續選購/選用其他著數優惠券",
        english: "✻You may browse the menu/<br/>use other coupons"
    },
    BNYYCDCFS: {
        chinese: "抱歉！優惠不適用於此點餐方式",
        english: "Sorry! The offer is invalid for the<br/>selected dining method"
    },
    XYQTYHQFK: {
        chinese: "✻您可選用其他著數優惠券/前往付款",
        english: "✻You may use other coupons/<br/>proceed to checkout"
    },
    YHMYSX: {
        chinese: "抱歉！優惠碼已失效或不存在",
        english: "Sorry! The promo code has expired<br/>or does not exist"
    },
    YHMZDSJSY: {
        chinese: "優惠碼須於指定時段內使用",
        english: "The promo code is valid for a specific time"
    },
    NKYGGSJ: {
        chinese: "✻您可更改時間/使用其他著數優惠券",
        english: "✻You may change the time/<br/>use other coupons"
    },
    CDYFH:{
        chinese: "返回",
        english: "Cancel"
    },
    BNZCCTSY:{
        chinese: "抱歉！優惠碼不適用於此餐廳",
        english: "Sorry! The promo code is invalid<br/>for this restaurant"
    },
    CDYBSYMD:{
        chinese: "抱歉！優惠不適用於此餐廳或已售罄",
        english: "Sorry! The offer has ended<br/>or is sold out"
    },
    SCSJYG:{
        chinese: "抱歉！所選送餐時間已過,<br/>請於付款前更改時間",
        english: "Sorry! The preferred delivery time has passed, please change it before proceeding to checkout"
    },

}
export default language
