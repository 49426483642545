import { createStore } from 'vuex';
import { postPaas } from "../assets/utils/request"
import { copyData } from "../assets/utils/copy"

export default createStore({
  state: {
    tabbarShow: true,
    language: 1,
    userInfo: null,
    sendType: null,
    cart: [],//购物车
    updateProIndex: null,//修改的购物车中的餐品的下标
    placeInfo: null,
    position: {},//app传过来的经纬度
    pageTypeNum: 1,
    location: null,//定位经纬度
    locationName: "",//定位地区的名称
    time: null,//H01 早餐 H02 午餐 H03 下午茶 H04 晚餐
    userTitle: null,
    addressTag: null,//地址标签
    menuType: null,//当钱的餐单业务类型
    totalPrice: 0,//购物车总价钱
    addPlaceInfo: {},//添加地址時的地址信息
    deviceId: null,
    hashTag: null,
    money: ["$", "$"],
    moneyMark: ["HKD", "MOP"],
    phone: [852, 853],
    area: 0,
    app: false,
    isYuu: false,
    routers: ["OrderFood"],
    transition: "slide-left",
    clearCou: true,//是否要清除優惠券信息
    setNotice: false,
    user_longlat: null,//用户经纬度
    showCoupon: false,
    jump: true,
    showCart: false,
    reserveDate: null,
    yuuInfo: null,
    yuuLoginInfo: null,
    isPC: false,
    couponInfo: null,//优惠券信息
    appInfo: null,
    H5Seting: null
  },
  getters: {
    getTabbarShow(state) {
      return state.tabbarShow
    }
  },
  mutations: {
    // 
    saveH5Seting(state, payload) {
      state.H5Seting = payload
    },
    // app跳过来的信息
    saveAppInfo(state, payload) {
      state.appInfo = payload
    },
    // 优惠券信息
    saveCouponInfo(state, payload) {
      if (payload) {
        if (payload.voucherType == 1 || payload.voucherType == 4) {
          payload.showPrice = payload.voucherAmount / 100
        } else if (payload.voucherType == 2) {
          payload.showPrice = 0
        } else {
          payload.showPrice = payload.canDaoFixPrice ? payload.canDaoFixPrice / 100 : 0
        }
      }
      state.couponInfo = payload;
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 是否是PC端
    saveIsPC(state, payload) {
      state.isPC = payload

    },
    saveUpdateProIndex(state, payload) {
      state.updateProIndex = payload
    },
    saveYuuInfo(state, payload) {
      state.yuuInfo = payload
    },
    saveYuuLoginInfo(state, payload) {
      state.yuuLoginInfo = payload
    },
    // 是否是yuu跳转进来
    saveYuu(state, payload) {
      state.isYuu = payload
    },
    // 预约时间
    saveReserveDate(state, payload) {
      state.reserveDate = payload
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 是否顯示購物車
    changeShowCart(state, payload) {
      state.showCart = payload
    },
    updateTabbarShow(state, payload) {
      state.tabbarShow = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
    // 清除個人信息
    clearUserInfo(state) {
      state.userInfo = null
    },
    // 修改语言
    setLanguage(state, payload) {
      state.language = payload;
      this.dispatch("getHashtag")
      this.dispatch("getTitle")
    },
    // 修改购物车折扣餐品价格
    editCartProductDisPrice(state, payload) {
      if (!payload.uids) return;
      if (!state.cart || !state.cart.length) return
      for (var i = 0; i < state.cart.length; i++) {
        if (payload.uids.indexOf(state.cart[i].uid) > -1) {
          state.cart[i].price = payload.proPrice[state.cart[i].uid].productDisplayPrice + payload.proPrice[state.cart[i].uid].price
        }
      }
    },
    //创建购物车
    createCart(state, payload) {
      if (payload.toString() == "{}" || payload.toString() == "") return;
      if (payload.update) {
        state.cart[state.updateProIndex] = payload.product
        return
      }
      var hasProduct = false;
      var productIndex = [];
      // *
      if (payload.product.isCouponPro) {
        state.cart.forEach((item, index) => {
          if (item.isCouponPro) {
            state.cart.splice(index, 1)
            return
          }
        })
        if (payload.product.num != 0) {
          state.cart.push(payload.product)
          localStorage.setItem("store", JSON.stringify(state))
        } else {
          localStorage.setItem("store", JSON.stringify(state))
        }
        return
      }
      // *
      for (var i = 0; i < state.cart.length; i++) {
        if (state.cart[i].uid == payload.product.uid) {
          var hasoldProArr = [];
          if (payload.product.type == 1) {
            var hasOldProSingle = false;
            var singleOldListRequirements = state.cart[i].listRequirements;
            if (singleOldListRequirements && singleOldListRequirements.length != 0) {
              var singleOldPidJson = JSON.stringify(singleOldListRequirements[0].pidData);
              var singleNewPidJson = JSON.stringify(payload.product.listRequirements[0].pidData)
              if (singleOldPidJson == singleNewPidJson) {
                hasOldProSingle = true
              }
            } else {
              hasOldProSingle = true
            }
            hasoldProArr.push(hasOldProSingle)
          } else {
            var oldG = copyData(state.cart[i].selectedFood);
            var newG = copyData(payload.product.selectedFood);
            for (var o = 0; o < oldG.groups.length; o++) {
              for (var oM = 0; oM < oldG.groups[o].mains.length; oM++) {
                for (var oMG = 0; oMG < oldG.groups[o].mains[oM].groups.length; oMG++) {
                  var OP = oldG.groups[o].mains[oM].groups[oMG].products
                  for (var oMGP = 0; oMGP < OP.length; oMGP++) {

                    var hasOldPro = false;
                    for (var n = 0; n < newG.groups.length; n++) {
                      for (var nM = 0; nM < newG.groups[n].mains.length; nM++) {
                        for (var nMG = 0; nMG < newG.groups[n].mains[nM].groups.length; nMG++) {
                          var NP = newG.groups[n].mains[nM].groups[nMG].products
                          for (var nMGP = 0; nMGP < NP.length; nMGP++) {
                            if (OP[oMGP].pid == NP[nMGP].pid && OP[oMGP].num == NP[nMGP].num) {
                              if (OP[oMGP].listPropertys && OP[oMGP].listPropertys.length > 0) {
                                var oPidJsonObj = {};
                                var nPidJsonObj = {};
                                var hadOldPro = true;
                                for (var ol = 0; ol < OP[oMGP].listPropertys.length; ol++) {
                                  var oPidJson = JSON.stringify(OP[oMGP].listPropertys[ol].pidData);
                                  if (oPidJsonObj[oPidJson]) {
                                    oPidJsonObj[oPidJson] += OP[oMGP].listPropertys[ol].num
                                  } else {
                                    oPidJsonObj[oPidJson] = OP[oMGP].listPropertys[ol].num
                                  }
                                }
                                for (var nl = 0; nl < NP[nMGP].listPropertys.length; nl++) {
                                  var nPidJson = JSON.stringify(NP[nMGP].listPropertys[nl].pidData);
                                  if (nPidJsonObj[nPidJson]) {
                                    nPidJsonObj[nPidJson] += NP[nMGP].listPropertys[nl].num
                                  } else {
                                    nPidJsonObj[nPidJson] = NP[nMGP].listPropertys[nl].num
                                  }
                                }
                                for (var key in oPidJsonObj) {
                                  if (!nPidJsonObj[key] || nPidJsonObj[key] != oPidJsonObj[key]) {
                                    hadOldPro = false;
                                    break
                                  }
                                }
                                hasOldPro = hadOldPro;
                                // for (var ol = 0; ol < OP[oMGP].listPropertys.length; ol++) {
                                // var Opropertys = OP[oMGP].listPropertys[ol].propertys;
                                // for (var olp = 0; olp < Opropertys.length; olp++) {
                                // for (var nl = 0; nl < NP[nMGP].listPropertys.length; nl++) {
                                // var Npropertys = NP[nMGP].listPropertys[nl].propertys;
                                // for (var nlp = 0; nlp < Npropertys.length; nlp++) {
                                //   if (Opropertys[olp].pid == Npropertys[nlp].pid) {
                                //     var hasOldPropertyArr = []
                                //     for (var ot = 0; ot < Opropertys[olp].items.length; ot++) {
                                //       var hasOldProperty = false;
                                //       for (var nt = 0; nt < Npropertys[nlp].items.length; nt++) {
                                //         if (Opropertys[olp].items[ot].uid == Npropertys[nlp].items[nt].uid) {
                                //           hasOldProperty = true
                                //         }
                                //       }
                                //       hasOldPropertyArr.push(hasOldProperty)
                                //     }
                                //     if(hasOldPropertyArr.indexOf(false) < 0){
                                //       hasOldPro = true
                                //     }
                                //   }
                                // }
                                // }
                                // }
                                // }
                              } else {
                                hasOldPro = true
                              }
                            }
                          }
                        }
                      }
                    }
                    hasoldProArr.push(hasOldPro)
                  }
                }
              }
            }
          }
          if (hasoldProArr.indexOf(false) < 0) {
            hasProduct = true;
            productIndex.push(i)
            break
          }
        }
      }
      if (hasProduct) {
        var hasOnce = false;
        for (var k = 0; k < productIndex.length; k++) {
          if (payload.product.type == 1) {
            // var newProduct = payload.product.listRequirements
            // var newProductJson = JSON.stringify(newProduct)
            // var oldProduct = state.cart[productIndex[k]].listRequirements
            // var oldProductJson = JSON.stringify(oldProduct)
            // if (newProductJson == oldProductJson) {
            if (payload.product.num == 0) {

              state.cart.splice(productIndex[k], 1)
              localStorage.setItem("store", JSON.stringify(state))
              return;
            }
            var oldNum = Number(state.cart[productIndex[k]].num)
            var newNum = Number(payload.product.num)
            if (payload.cart) {
              state.cart[productIndex[k]].num = newNum
            } else {
              state.cart[productIndex[k]].num = newNum + oldNum
            }
            hasOnce = true
            break
            // }
          } else {
            // var newPro = payload.product
            // var newProJson = JSON.stringify(newPro.selectedFood)

            // var oldPro = state.cart[productIndex[k]]
            // var oldProJson = JSON.stringify(oldPro.selectedFood)

            // if (newProJson == oldProJson) {
            if (payload.product.num == 0) {
              state.cart.splice(productIndex[k], 1)
              localStorage.setItem("store", JSON.stringify(state))
              return;
            }
            var newProNum = Number(payload.product.num)
            var oldProNum = Number(state.cart[productIndex[k]].num)

            if (payload.cart) {
              state.cart[productIndex[k]].num = newProNum
            } else {
              state.cart[productIndex[k]].num = newProNum + oldProNum
            }
            hasOnce = true
            break
            // }
          }
        }
        if (!hasOnce) {
          state.cart.push(payload.product)
        }
      } else {
        if (payload.product.num != 0) {
          state.cart.push(payload.product)
        }
      }
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 清空购物车
    clearCart(state) {
      state.cart = []
      localStorage.setItem("store", JSON.stringify(state))
    },
    // 完整替换购物车
    replaceCart(state, payload) {
      state.cart = payload
    },
    // 购物车总价钱
    setTotalPrice(state, payload) {
      state.totalPrice = payload
    },
    // 保存地址信息
    savePlace(state, payload) {
      state.placeInfo = payload
    },
    // 退出登陆
    logout(state) {
      state.userInfo = null
    },
    // 保存app传过来的位置信息
    savePosition(state, payload) {
      state.position = payload
    },
    // 改变tab页面
    changeTabPage(state, payload) {
      state.pageTypeNum = payload
    },
    // 定位的经纬度
    saveLocation(state, payload) {
      state.location = payload
    },
    // 定位的地区名称
    saveLocationName(state, payload) {
      state.locationName = payload
    },
    // 餐品时间
    saveTime(state, payload) {
      state.time = payload
    },
    // 选取优惠券 保存餐品时间
    saveTimeSelectCoupon(state) {
      var nowTime = new Date().getTime(); //当前时间
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      var date = year + "/" + month + "/" + day + " ";
      var breakfast = new Date(date + "10:59").getTime();
      var lunch = new Date(date + "14:29").getTime();
      var tea = new Date(date + "17:29").getTime();
      var saveTimeType;
      if (nowTime < breakfast) {
        saveTimeType = "H01";
      } else if (nowTime < lunch) {
        saveTimeType = "H02";
      } else if (nowTime < tea) {
        saveTimeType = "H03";
      } else {
        saveTimeType = "H04";
      }
      state.time = saveTimeType
    },
    //保存用户title标签
    saveUserTitle(state, payload) {
      state.userTitle = payload
    },
    // 保存地址标签
    savePlaceTag(state, payload) {
      state.addressTag = payload
    },
    // 保存当前的餐单业务类型
    saveMenuType(state, payload) {
      state.menuType = payload
    },
    save(state, payload) {
      state = payload
    },
    // 保存hashTag
    saveHashtag(state, payload) {
      state.hashTag = payload
    },
    // 保存地區
    saveArea(state, payload) {
      state.area = payload
    },
    // 保存是否是app
    saveApp(state, payload) {
      state.app = payload
    },
    // 保存添加地址是的數據
    saveAddPlaceInfo(state, payload) {
      state.addPlaceInfo = payload
    },
    // 清除添加時的數據
    clearAddPlaceInfo(state) {
      state.addPlaceInfo = null
    },
    // h还原vuex数据
    reloadVuex(state) {
      state.tabbarShow = true
      state.userInfo = null
      state.sendType = null
      state.cart = []//
      state.placeInfo = null
      state.position = {}//app传过来的经纬度
      state.pageTypeNum = 1
      state.location = null//定位经纬度
      state.locationName = ""//定位地区的名称
      state.time = null//H01 早餐 H02 午餐 H03 下午茶 H04 晚餐
      state.menuType = null//当钱的餐单业务类型
      state.totalPrice = 0//购物车总价钱
      state.addPlaceInfo = null//添加地址時的地址信息
      state.app = false
      state.deviceId = null
    },
    clearRouter(state) {
      state.routers = []
    },
    saveRouter(state, payload) {
      state.routers = [payload]
    },
    // 保存传过来的机型
    saveDeviceId(state, payload) {
      state.deviceId = payload
    },
    chnageTransion(state, payload) {
      state.transition = payload
    },
    changeClearCou(state, payload) {
      state.clearCou = payload
    },
    chageSetNotice(state, payload) {
      state.setNotice = payload
    },
    // 用户经纬度
    saveUserPlace(state, payload) {
      state.user_longlat = payload
    },
    // 是否弹出优惠券
    saveShowCoupon(state, payload) {
      state.showCoupon = payload
    },
    saveJumpDetail(state, payload) {
      state.jump = payload
    },
    // 修改用戶信息
    updateUserInfo(state, payload) {
      for (var key in payload) {
        state.userInfo[key] = payload[key]

      }
    }
  },
  actions: {
    async getTitle() {
      let params = {
        actionName: "candao.member.miscUserTitles",
        content: {},
      };

      let placeParams = {
        actionName: "candao.member.miscAddressTag",
        content: {},
      };
      if (this.state.language == 1) {
        params.content.language = "TC";
        placeParams.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        result.data.data.splice(result.data.data.length - 1, 1);
        this.commit("saveUserTitle", result.data.data);
      }
      let placeResult = await postPaas("UserUnify", placeParams);
      for (var i = 0; i < placeResult.data.data.length; i++) {
        if (placeResult.data.data[i].tagId == 1) {
          placeResult.data.data[i].tagName = this.state.language == 1 ? "屋企" : "Home"
        } else if (placeResult.data.data[i].tagId == 2) {
          placeResult.data.data[i].tagName = this.state.language == 1 ? "公司" : "Office"
        } else if (placeResult.data.data[i].tagId == 3) {
          placeResult.data.data[i].tagName = this.state.language == 1 ? "其他" : "Other"
        }
      }
      this.commit("savePlaceTag", placeResult.data.data);
    },
    async getHashtag() {
      let params = {
        actionName: "candao.member.miscHashTag",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.commit("saveHashtag", result.data.data);
      }
    },
  },
  modules: {
  }
})
