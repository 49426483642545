<template>
  <div>
    <div class="tabbar">
      <div class="main" @click="selectPage(1)">
        <img v-show="page != 1" src="@/assets/icon/main.png" alt="" />
        <img v-show="page == 1" src="@/assets/icon/mainActive.png" alt="" />
        <p v-if="$store.state.language == 1">點餐</p>
        <p v-else>Order</p>
      </div>
      <div class="coupon" :class="{yuuActive:isYuu}" @click="selectPage(2)">
        <img v-show="page != 2" src="@/assets/icon/couponBar.png" alt="" />
        <img
          v-show="page == 2"
          src="@/assets/icon/couponBarActive.png"
          alt=""
        />
        <p v-if="$store.state.language == 1">優惠券</p>
        <p v-else>Coupons</p>
      </div>
      <div class="order" :class="{yuuActive:isYuu}" @click="selectPage(3)">
        <img v-show="page != 3" src="@/assets/icon/order.png" alt="" />
        <img v-show="page == 3" src="@/assets/icon/orderActive.png" alt="" />
        <p v-if="$store.state.language == 1">訂單記錄</p>
        <p v-else>My Orders</p>
      </div>
      <div class="mine" @click="selectPage(4)">
        <img v-show="page != 4" src="@/assets/icon/mine.png" alt="" />
        <img v-show="page == 4" src="@/assets/icon/mineActive.png" alt="" />
        <p v-if="$store.state.language == 1">我的</p>
        <p v-else>Account</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import language from "../assets/js/language.js";

export default {
  name: "Tabbar",
  data() {
    return {
      // page: this.$store.state.pageTypeNum,
      main: "",
      coupon: "",
      order: "",
      mine: "",
      lanType: this.$store.state.language,
    };
  },
  computed: {
    ...mapState(["isYuu"]),
    page() {
      return this.$store.state.pageTypeNum;
    },
  },
  methods: {
    selectPage(page) {
      var path = "home";
      if (page == 1) {
        path = "home";
      } else if (page == 2) {
        path = "coupon/1";
      } else if (page == 3) {
        path = "orderList";
      } else if (page == 4) {
        path = "mine";
      }
      if (this.isYuu) {
        if (page == 2 || page == 3) return;
      }
      this.$store.commit("changeTabPage", page);
      // this.page = page;
      this.$router.push("/" + path);
      this.dataLayerPush(page);
    },
    // 埋点
    dataLayerPush(page) {
      var event;
      if (page == 1) {
        event = "product_info";
      } else if (page == 2) {
        event = "coupon_info";
      } else if (page == 3) {
        event = "reorder_page";
      } else if (page == 4) {
        event = "member_center";
      }
      window.dataLayer.push({
        event,
        page_category: "homepage",
      });
    },
    setLanguage() {
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      this.main = language.main[languageType];
      this.coupon = language.coupon[languageType];
      this.order = language.order[languageType];
      this.mine = language.mine[languageType];
    },
  },
  created() {
    this.setLanguage();
  },
};
</script>
<style scoped>
.tabbar {
  width: 3.75rem;
  height: 0.46rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* position: fixed; */
  /* bottom: 0; */
  padding-bottom: 0.1rem;
  border-top: 0.01rem solid #ddd;
  z-index: 999999999999999999999;
  background-color: #fff;
}
.tabbar img {
  width: 0.26rem;
  height: 0.26rem;
  object-fit: cover;
}
.tabbar div {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tabbar p {
  height: 0.14rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.14rem;
  color: #02050c;
  margin-top: 0.02rem;
}
.yuuActive{
  opacity: 0.5 !important;
}
</style>