import axios from "axios";
import store from '../../store'
import { Toast } from 'vant';
Toast.allowMultiple();
let loadingArr=[]
var baseURL;
var qcTest = false;

var href = window.location.href.split("/")
baseURL = href[0] + "//"
for (var i = 1; i < href.length; i++) {
    baseURL += href[i]
    if (href[i]) {
        break
    }
}
const sellerKey = qcTest ? "2a84992f8e86d642" : "40a443f1ced7f597"
var deviceId;
var u = navigator.userAgent;
if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
    deviceId = "iPhone"
} else if (u.indexOf("iPad") > -1 || u.indexOf("ipad") > -1) {
    deviceId = "iPad"
} else if (u.indexOf("Windows") > -1 || u.indexOf("windows") > -1) {
    deviceId = "Windows"
} else if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
    deviceId = "Android"
} else {
    deviceId = "Unknown device"
}
// Paas
const requestPaas = axios.create({
    baseURL,
    timeout: 5000,
    withCredentials: true,
})
// var loading;
// 请求拦截器
requestPaas.interceptors.request.use(
    config => {
        config.url += "?_name=" + config.data.actionName
        if (store.state.app) {
            deviceId = store.state.deviceId
        }
        config.data.key = sellerKey;

        config.data.content.crmCode = "azure";
        config.data.content.brand = "KFCHK";
        config.data.content.source = "Web";
        if (config.data.content.jrgAzureParams) {
            config.data.content.jrgAzureParams.deviceId = deviceId
            config.data.content.jrgAzureParams.crmCode = "azure";
            config.data.content.jrgAzureParams.brand = "KFCHK";
            config.data.content.jrgAzureParams.source = "Web";
        }
        config.data.content.deviceId = deviceId
        if (config.data.content.device) {
            config.data.content.device = deviceId
        }
        config.data.langType = store.state.language == 2 ? 2 : 1
        if (store.state.language == 2) {
            config.data.content.language = "E"
        } else {
            config.data.content.language = "TC"
        }
        if (store.state.area == 1) {
            config.data.content.brand = "KFCMO"
        }
        loadingArr.push(Toast.loading({
            forbidClick: true,
            duration: 0
        }));
        return config

    }, error => {
        return Promise.reject(error)
    }
);
// 响应拦截器
requestPaas.interceptors.response.use(
    res => {
        loadingArr.splice(0,1)[0].clear()
        return res.data
    },
    error => {
        var errorTest = store.state.language == 2 ? "Connection error has occurred, please retry" : "網絡異常，請重試"
        Toast(errorTest)
        loadingArr.splice(0,1)[0].clear()

        return Promise.reject(error)
    }
)
// post请求
export function postPaas(url, params) {
    // url="/api/"+url
    if (!params.content.deviceId) {
        params.content.deviceId = deviceId
    }
    return requestPaas.post(
        url,
        params,
        {
            headers: { 'content-type': 'multipart/form-data' },
        }
    )
}
// DC
const requestDC = axios.create({
    baseURL,
    timeout: 5000,
    withCredentials: true,
})
// 请求拦截器
requestDC.interceptors.request.use(
    config => {
        config.url += "?_name=" + config.data.actionName
        if (store.state.app) {
            deviceId = store.state.deviceId
        }
        config.data.key = sellerKey;
        config.data.content.crmCode = "azure";
        config.data.content.brand = "KFCHK";
        config.data.content.source = "Web";
        config.data.content.deviceId = deviceId
        if (config.data.content.jrgAzureParams) {
            config.data.content.jrgAzureParams.deviceId = deviceId;
            config.data.content.jrgAzureParams.crmCode = "azure";
            config.data.content.jrgAzureParams.brand = "KFCHK";
            config.data.content.jrgAzureParams.source = "Web";
        }
        if (config.data.content.device) {
            config.data.content.device = deviceId
        }
        config.data.langType = store.state.language == 2 ? 2 : 1
        if (store.state.language == 2) {
            config.data.content.language = "E"
        } else {
            config.data.content.language = "TC"
        }
        if (store.state.area == 1) {
            config.data.content.brand = "KFCMO"
        }
        loadingArr.push(Toast.loading({
            forbidClick: true,
            duration: 0
        }));
        return config
    }, error => {
        return Promise.reject(error)
    }
);
// 响应拦截器
requestDC.interceptors.response.use(
    res => {
        loadingArr.splice(0,1)[0].clear()

        return res.data
    },
    error => {
        var errorTest = store.state.language == 2 ? "Connection error has occurred, please retry" : "網絡異常，請重試"
        Toast(errorTest)
        loadingArr.splice(0,1)[0].clear()

        return Promise.reject(error)
    }
)
// post请求
export function postDC(url, params) {
    // url="/api/"+url
    if (!params.content.deviceId) {
        params.content.deviceId = deviceId
    }
    return requestDC.post(
        url,
        params,
        {
            headers: { 'content-type': 'multipart/form-data' },
        }
    )
}
// 没有代理
const requestPlace = axios.create({
    // baseURL: "https://maps.googleapis.com/maps/api/place/findplacefromtext/",

})
// 请求拦截器
requestPlace.interceptors.request.use(
    config => {
        return config
    }, error => {
        return Promise.reject(error)
    }
);
export function getPlace(url, params) {
    return requestPlace.get(url, { params })
}
export function postPlace(url, params) {
    return requestPlace.post(
        url,
        params,

    )
}

