<template>
  <div id="" class="app" @dblclick="doubleClick">
    <router-view v-slot="{ Component }">
      <transition name="">
        <keep-alive :include="$store.state.routers">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <div class="bar">
      <Tabbar v-if="tabbarShow"></Tabbar>
    </div>
    <div v-show="$store.state.setNotice">
      <set-notice
        :leftBtn="leftBtn"
        :rightBtn="rightBtn"
        :title="title"
        :content="content"
        @leftEvent="leftEvent"
        @rightEvent="rightEvent"
      ></set-notice>
    </div>
  </div>
</template>

<script>
import Tabbar from "./components/Tabbar.vue";
import SetNotice from "./components/common/SetNotice.vue";

import appboy from "@braze/web-sdk";
import "./assets/js/language.js";
import { postPlace, getPlace, postPaas, postDC } from "./assets/utils/request";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      H5Info: null,
      pageParam: null,
    };
  },
  methods: {
    async getH5Info() {
      var params = {
        actionName: "candao.storeOwn.getH5Setting",
        content: {},
      };
      let result = await postDC("Action", params);
      if (result.status == 1) {
        this.$store.commit("saveH5Seting", result.data);
      }
    },
    doubleClick() {
      console.log(1);
    },
    leftEvent() {
      this.$store.commit("chageSetNotice", false);
    },
    async rightEvent() {
      var userInfo = this.$store.state.userInfo;
      // 修改用户信息
      let params = {
        actionName: "candao.member.userUpdate",
        content: {
          genderId: userInfo.genderId,
          birthday: {
            year: userInfo.birthday.month,
            month: userInfo.birthday.month,
            day: userInfo.birthday.day,
          },
          firstName: userInfo.firstName || userInfo.lastName,
          receiveNews: true,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        var payload = {
          receiveNews: true,
        };
        this.$store.commit("updateUserInfo", payload);
        this.$store.commit("chageSetNotice", false);
      }
    },
    async getLocation() {
      const url =
        "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAsvvcMhcg_aVl8llTs-xJwwhVujT9yKLo&libraries=places";
      let result = await postPlace(url, {});
      if (result.status != 200) {
        return;
      }
      const lat = result.data.location.lat;
      const lng = result.data.location.lng;
      const investigationUrl =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyAsvvcMhcg_aVl8llTs-xJwwhVujT9yKLo";
      let placeResult = await getPlace(investigationUrl, {});
      if (placeResult.status == 200) {
        this.$store.commit(
          "saveLocation",
          placeResult.data.results[0].geometry.location
        );
        var failLoca =
          this.$store.state.language == 1 ? "定位失敗" : "To locate failure";
        this.$store.commit(
          "saveLocationName",
          placeResult.data.results[0].formatted_address || failLoca
        );
        if (!this.$store.state.placeInfo) {
          var copyInfo = {
            building: placeResult.data.results[0].formatted_address,
            district: placeResult.data.results[0].formatted_address,
            lat: placeResult.data.results[0].geometry.location.lat,
            lng: placeResult.data.results[0].geometry.location.lng,
          };
          this.$store.commit("savePlace", copyInfo);
          this.$store.commit("saveUserPlace", [copyInfo.lat, copyInfo.lng]);
        }
      } else {
        return;
      }
    },
    async getTitle() {
      let params = {
        actionName: "candao.member.miscUserTitles",
        content: {},
      };

      let placeParams = {
        actionName: "candao.member.miscAddressTag",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
        placeParams.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        result.data.data.splice(result.data.data.length - 1, 1);
        this.$store.commit("saveUserTitle", result.data.data);
      }
      let placeResult = await postPaas("UserUnify", placeParams);
      this.$store.commit("savePlaceTag", placeResult.data.data);
    },
    async getHashtag() {
      let params = {
        actionName: "candao.member.miscHashTag",
        content: {},
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$store.commit("saveHashtag", result.data.data);
      }
    },
    async loginYuu() {
      let params = {
        actionName: "candao.member.yuuProfileGet",
        content: {
          yuuToken: this.pageParam.idtoken,
        },
      };
      let yuuUserInfo = await postPaas("UserUnify", params);
      if (yuuUserInfo.status == 1) {
        if (
          !yuuUserInfo.data ||
          !yuuUserInfo.data.memberProfileList ||
          !yuuUserInfo.data.memberProfileList.length
        ) {
          return;
        }
        var memberProfileList = yuuUserInfo.data.memberProfileList[0];
        var hasAddress = false;
        var failLoca =
          this.$store.state.language == 1 ? "定位失敗" : "To locate failure";
        memberProfileList.addresses.forEach((val) => {
          if (val.type == this.pageParam.addresstype) {
            hasAddress = true;
            if (!val.postalCode) {
              this.$store.commit("saveLocationName", failLoca);
            } else {
              let postalCode = {
                postalCodeOld: val.postalCode,
              };
              let placeInfo = val.postalCode.split(",");
              placeInfo.forEach((val) => {
                let infoItem = val.split(":");
                if (infoItem[0].indexOf("lat") > -1) {
                  postalCode.lat = Number(infoItem[1].split("'").join(""));
                }
                if (infoItem[0].indexOf("lng") > -1) {
                  postalCode.lng = Number(infoItem[1].split("'").join(""));
                }
              });
              var copyInfo = {
                building: val.address2,
                district: val.region,
                lat: postalCode.lat,
                lng: postalCode.lng,
              };
              this.$store.commit("savePlace", copyInfo);
              val.postalCode = postalCode;
              if (val.postalCode.lat && val.postalCode.lng) {
                yuuUserInfo.data.addressNow = val;
              }
            }
            return;
          }
        });
        if (!hasAddress) {
          if (
            memberProfileList.addresses &&
            memberProfileList.addresses.length > 0
          ) {
            if (
              memberProfileList.addresses[0].postalCode &&
              memberProfileList.addresses[0].postalCode.lat &&
              memberProfileList.addresses[0].postalCode.lng
            ) {
              yuuUserInfo.data.addressNow = memberProfileList.addresses[0];
            } else {
              this.$store.commit("saveLocationName", failLoca);
            }
          }
        }
        this.$store.commit("saveYuuInfo", yuuUserInfo.data);
        this.getUserByExtUserId(yuuUserInfo.data);
      } else {
        return;
      }
    },
    async getUserByExtUserId(yuuInfo) {
      let paramsOne = {
        actionName: "candao.user.getUserByExtUserId",
        content: {
          extUserId: yuuInfo.cardNo,
          phone: yuuInfo.memberProfileList[0].phoneNo.slice(4),
        },
      };
      let canDaoUser = await postDC("Action", paramsOne);
      if (canDaoUser.status == 1) {
        this.$store.commit("saveYuuLoginInfo", canDaoUser.data);
      }
    },
    ...mapMutations(["saveYuu", "clearUserInfo"]),
  },
  created() {
    console.log("2022.04.25 1.2");
    // if (!window.location.href.startsWith("https")) {
    //   var locahref = window.location.href;
    //   locahref=locahref.replace("http", "https");
    //   window.location.href = locahref;
    //   return;
    // }
    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
    let u = navigator.userAgent;
    let isPC = true;
    if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
      isPC = false;
    } else if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
      isPC = false;
    }
    this.$store.commit("saveIsPC", isPC);
    var nowTime = new Date().getTime(); //当前时间
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    var day = new Date().getDate();
    var date = year + "/" + month + "/" + day + " ";
    var breakfast = new Date(date + " 10:59").getTime();
    var lunch = new Date(date + " 14:29").getTime();
    var tea = new Date(date + " 17:29").getTime();
    var saveTimeType;
    if (nowTime < breakfast) {
      saveTimeType = "H01";
    } else if (nowTime < lunch) {
      saveTimeType = "H02";
    } else if (nowTime < tea) {
      saveTimeType = "H03";
    } else {
      saveTimeType = "H04";
    }
    this.$store.commit("saveTime", saveTimeType);
    this.getTitle();
    this.getHashtag();
    var KFCClear = localStorage.getItem("KFCClear");
    if (KFCClear) {
      localStorage.removeItem("KFCClear");
      this.$store.commit("clearCart");
    }
    this.saveYuu(false);
    var href = window.location.href;
    localStorage.setItem("yuuUrl", window.location.href);
    if (href.indexOf("idtoken") > -1) {
      href = href.split("?")[1].split("#");
      if (href.length > 0) {
        var param = {};
        var hrefParam = href[0].split("&");
        hrefParam.forEach((val) => {
          var P = val.split("=");
          if (P[0] == "addresstype") {
            var typeTime = P[1].split("T");
            P[1] = typeTime[0] + "T" + decodeURIComponent(typeTime[1]);
          }
          param[P[0]] = P[1];
          if (P[0] == "idtoken") {
            this.saveYuu(true);
            this.clearUserInfo();
          }
        });
        this.pageParam = param;
        if (this.$store.state.isYuu) {
          let LANG = this.pageParam.lang == "en" ? 2 : 1;
          this.$store.commit("setLanguage", LANG);
          this.loginYuu();
        }
      }
    }
    let yuuParam = this.pageParam || {};
    yuuParam.isYuu = this.$store.state.isYuu;
    localStorage.setItem("yuuParam", JSON.stringify(yuuParam));
    if (!this.$store.state.isYuu) {
      this.getLocation();
    }
    appboy.initialize("b495d5a8-70ff-4163-9057-9c4f946901a4", {
      baseUrl: "sdk.iad-06.braze.com",
    });
  },
  components: {
    Tabbar,
    SetNotice,
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      //判断是否显示tabbar
      if (
        to.path == "/" ||
        to.name == "Home" ||
        to.name == "Coupon" ||
        to.path == "/orderList" ||
        to.path == "/mine" ||
        to.path == "/index.html" ||
        to.path == "/dist/index.html"
      ) {
        this.$store.commit("updateTabbarShow", true);
      } else {
        this.$store.commit("updateTabbarShow", false);
      }
      var tabType = 1;
      if (
        to.path == "/" ||
        to.name == "Home" ||
        to.path == "/index.html" ||
        to.path == "/dist/index.html"
      ) {
        tabType = 1;
      } else if (to.name == "Coupon") {
        tabType = 2;
      } else if (to.path == "/orderList") {
        tabType = 3;
      } else if (to.path == "/mine") {
        tabType = 4;
      }
      this.$store.commit("changeTabPage", tabType);
    },
  },
  computed: {
    tabbarShow() {
      return this.$store.getters.getTabbarShow;
    },
    leftBtn() {
      return this.$store.state.language == 1 ? "不允許" : "Don’t Allow";
    },
    rightBtn() {
      return this.$store.state.language == 1 ? "允許" : "Allow";
    },
    title() {
      return this.$store.state.language == 1
        ? "「KFC HK」想要傳送通知"
        : '"KFC Hong Kong" Would Like to Send You Notifications';
    },
    content() {
      return this.$store.state.language == 1
        ? "通知可包含提示、聲音和圖像標記。可以在「設定」中進行設定"
        : "Notifications may include alerts, sounds and icon badges. These can be configured in Settings";
    },
  },
};
</script>
<style lang="scss">
*:not(html) {
  margin: 0;
  padding: 0;
  max-width: 3.75rem;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

body {
  min-height: 100vh;
  background-color: #fff;
  width: 3.75rem;
  margin-left: calc(50% - 1.875rem) !important;
}
html {
  width: 100%;
  height: auto !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
input {
  background-color: #fff;
}
input:focus {
  background-color: #fff;
}
input[type="password"]::-ms-reveal {
  display: none;
}
.van-cell__value {
  color: #03060d !important;
}
.popup div {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  text-align: center;
}

.van-overlay {
  left: 50% !important;
  transform: translateX(-50%);
}
.van-popup--bottom {
  left: 50% !important;
  transform: translateX(-50%);
}
.van-picker__confirm,.van-picker__cancel{
  font-size: .16rem !important;
}
.van-popup {
  transition: none !important;
  max-width: 3rem !important;
}

.van-picker__title.van-ellipsis{
  font-size: .18rem;
}

.van-swipe__indicator{
  width: .08rem !important;
  height: .08rem !important;
}

.router-link-exact-active {
  color: #42b983;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  text-decoration: none;
  color: #000;
}
.gmnoprint,
.gm-control-active.gm-fullscreen-control {
  display: none !important;
}
.van-popup {
  z-index: 99999999999999999999 !important;
}
#app::-webkit-scrollbar {
  display: none;
}
.app::-webkit-scrollbar {
  display: none;
}
.bar {
  position: fixed;
  bottom: 0rem;
  z-index: 99999999999;
}
.van-popup.van-popup--center.van-toast.van-toast--middle.van-toast--loading {
  background-color: rgba(0, 0, 0, 0) !important;
}
.van-toast__loading {
  color: #000 !important;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: all 0.2s;
}
.fade-leave-active {
  opacity: 0;
  transition: all 0.2s;
}
.mescroll-downwarp.mescroll-downwarp-reset {
  height: 60px;
}
.mescroll-downwarp .downwarp-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}
.titleColor {
  color: #474747;
}
</style>
