import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

const Home = () => import(/* webpackChunkName:"main" */ '../views/Home')
const Login = () => import(/* webpackChunkName:"login" */ '../views/login/Login')
const Register = () => import(/* webpackChunkName:"register" */ '../views/register/Register')
const Coupon = () => import(/* webpackChunkName:"coupon" */ '../views/Coupon')
const OrderList = () => import(/* webpackChunkName:"orderList" */ "../views/OrderList")
const Mine = () => import(/* webpackChunkName:"mine" */"../views/Mine")
const PersonInfo = () => import(/*webpackChunkName:"personInfo"*/"../views/mine/PersonInfo")
const EditPas = () => import(/*webpackChunkName:"editpas"*/"../views/mine/EditPas")
const VipCode = () => import(/*webpackChunkName:"vipcode"*/"../views/mine/VipCode")
const Score = () => import(/*webpackChunkName:"score"*/"../views/mine/Score")
const Agreement = () => import(/*webpackChunkName:"agreement"*/"../views/mine/Agreement")
const Place = () => import(/*webpackChunkName:"Place"*/"../views/mine/Place")
const SendPlace = () => import(/*webpackChunkName:"SendPlace"*/"../views/mine/SendPlace")
const CreditCard = () => import(/*webpackChunkName:"creditCard"*/"../views/mine/CreditCard")
const BindCard = () => import(/*webpackChunkName:"bindCard"*/"../views/mine/BindCard")
const Information = () => import(/*webpackChunkName:"information"*/"../views/mine/Information")
const InfoDetail = () => import(/*webpackChunkName:"infoDetail"*/"../views/mine/InfoDetail")
const SelectShop = () => import(/*webpackChunkName:"selectShop"*/"../views/main/SelectShop")
const ShopDetail = () => import(/*webpackChunkName:"shopDetail"*/"../views/main/ShopDetail")
const OrderFood = () => import(/*webpackChunkName:"orderFood"*/"../views/main/OrderFood")
const FoodDetail = () => import(/*webpackChunkName:"foodDetail"*/"../views/main/FoodDetail")
const ConfirmOrder = () => import(/*webpackChunkName:"confirmOrder"*/"../views/main/ConfirmOrder")
const MyScore = () => import(/*webpackChunkName:"confirmOrder"*/"../views/mine/MyScore")
const OrderDetail = () => import(/*webpackChunkName:"orderDetail"*/"../views/order/OrderDetail")
const RecomendFood = () => import(/*webpackChunkName:"recomendFood"*/"../views/main/RecomendFood")
const CouponDetail = () => import(/*webpackChunkName:"couponDetail"*/"../views/mine/CouponDetail")
const Transfer = () => import(/*webpackChunkName:"transfer"*/"../views/Transfer")



const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/dist/index.html',
    redirect: "/home",
  },
  {
    path: '/index.html',
    redirect: "/home",
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/coupon/:params',
    name: 'Coupon',
    component: Coupon,
  },
  {
    path: '/orderList',
    name: 'OrderList',
    component: OrderList,
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine,
  },
  {
    path: '/personInfo',
    name: 'PersonInfo',
    component: PersonInfo,
  },
  {
    path: '/editPas',
    name: 'EditPas',
    component: EditPas,
  },
  {
    path: '/vipcode',
    name: 'VipCode',
    component: VipCode,
  },
  {
    path: '/score/:params',
    name: 'Score',
    component: Score,
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement,
  },
  {
    path: '/place/:params',
    name: 'Place',
    component: Place,
  },
  {
    path: '/sendPlace/',
    component: SendPlace,
  },
  {
    path: '/creditCard/:params',
    name: 'CreditCard',
    component: CreditCard,
  },
  {
    path: '/bindCard',
    name: 'BindCard',
    component: BindCard,
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
  },
  {
    path: '/infoDetail',
    name: 'InfoDetail',
    component: InfoDetail,
  },
  {
    path: '/selectShop',
    name: 'SelectShop',
    component: SelectShop,
  },
  {
    path: '/shopDetail',
    name: 'ShopDetail',
    component: ShopDetail,
  },
  {
    path: '/orderFood/:type',
    name: 'OrderFood',
    meta: {
      first: false
    },
    component: OrderFood
  },
  {
    path: '/foodDetail/:info',
    name: 'FoodDetail',
    component: FoodDetail,
  },
  {
    path: '/confirmorder/:query',
    name: 'ConfirmOrder',
    component: ConfirmOrder,
  },
  {
    path: '/myScore/:params',
    name: 'MyScore',
    component: MyScore,
  },
  {
    path: '/orderDetail/:info',
    name: 'OrderDetail',
    component: OrderDetail,
  },
  {
    path: '/recomendFood',
    name: 'RecomendFood',
    component: RecomendFood,
  },
  {
    path: "/couponDetail",
    name: "CouponDetail",
    component: CouponDetail
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: Transfer
  },
  
]
var options = {
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
}
const router = createRouter(options)
router.beforeResolve((to) => {
  if (to.name != "ConfirmOrder" && to.name != "OrderFood" && to.name != "FoodDetail") {
    var cart = store.state.cart;
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].isCouponPro) {
        var productObj = {};
        var product = cart[i];
        product.num = 0;
        productObj.product = product;
        productObj.cart = true;
        store.commit("createCart", productObj);
        break;
      }
    }
    store.commit("saveCouponInfo", null);
    store.commit("changeClearCou", true);
    sessionStorage.setItem("firstJoinCoupon", 0);
    localStorage.removeItem("ShowCouponErrConfirm")
  }
  localStorage.setItem("store", JSON.stringify(store.state))
})

export default router
